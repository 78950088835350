title {
    font-size: 0rem;
}
   
.LocationEntry {
    font-family: 'Inconsolata', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: .5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    padding-left: 5rem;
    padding-right: 5rem;
}

h3 {
    text-align: center;
    font-family: 'Inconsolata', sans-serif;
    color: #3C1D09; 
}

h4 {
    margin-bottom: 0rem;
    color: #3C1D09; 
    font-family: 'Inconsolata', sans-serif;
}

p {
    text-align: center;
    font-family: 'Inconsolata', sans-serif;
    color:#3C1D09; 
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

ul {
     color: #3C1D09; 
     font-family: 'Inconsolata', sans-serif;
}

.location-form {
     color: #3C1D09;   
}

input {
    background-color: rgba(0,0,0,0.1);
    font-weight: bold;
    font-size: 1rem;
    color: #3C1D09;
    font-family: 'Inconsolata', sans-serif;
    border: none;
}

#location {
    height: 2rem;
    width: 15rem;
    margin-bottom: 1rem;
}

.location-form {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: .5rem;
    font-weight: bold;
}

.location-info {
    font-size: 1rem;
}

