@import url(https://fonts.googleapis.com/css?family=Inconsolata&display=swap);
title {
    font-size: 0rem;
}
   
.LocationEntry {
    font-family: 'Inconsolata', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: .5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    padding-left: 5rem;
    padding-right: 5rem;
}

h3 {
    text-align: center;
    font-family: 'Inconsolata', sans-serif;
    color: #3C1D09; 
}

h4 {
    margin-bottom: 0rem;
    color: #3C1D09; 
    font-family: 'Inconsolata', sans-serif;
}

p {
    text-align: center;
    font-family: 'Inconsolata', sans-serif;
    color:#3C1D09; 
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

ul {
     color: #3C1D09; 
     font-family: 'Inconsolata', sans-serif;
}

.location-form {
     color: #3C1D09;   
}

input {
    background-color: rgba(0,0,0,0.1);
    font-weight: bold;
    font-size: 1rem;
    color: #3C1D09;
    font-family: 'Inconsolata', sans-serif;
    border: none;
}

#location {
    height: 2rem;
    width: 15rem;
    margin-bottom: 1rem;
}

.location-form {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: .5rem;
    font-weight: bold;
}

.location-info {
    font-size: 1rem;
}


itle {
    font-size: 0rem;
   }
   
   .LandingPage {
       font-family: 'Inconsolata', sans-serif;
       color: #3C1D09; 
       display: flex;
       flex-direction: column;
       align-items: center;
       margin-bottom: 1rem;
       margin-left: 3rem;
       margin-right: 3rem;
       padding-left: 3rem;
       padding-right: 3rem;

   }

   .image {
    display: flex;
    flex-direction: column;
    background-position: center;
    background-image: url("https://user-images.githubusercontent.com/46304321/61234000-80500d00-a721-11e9-92e4-ab8a65bb9658.png");
    background-size: contain;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    height: 10rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}


h3 {
    text-align: center;
    margin-bottom: 3rem;
    color: #3C1D09;
}

button {
    text-align: center;
    font-family: 'Inconsolata', sans-serif;
    height: 2rem;
    width: 15rem;
    color: #3C1D09;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    margin-top: 3rem;
    background-color: Transparent;
    text-decoration: none;
    margin-bottom: 3rem;
    border: 1px solid #3C1D09;
    text-decoration: none;
}

button:hover {
    background-color: #3C1D09;
    opacity: 0.6;
    color: white;
  }

html{
    background-image: url("https://user-images.githubusercontent.com/46304321/61320813-c5477280-a7f9-11e9-821f-efbdbf9a39eb.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover; 
}

.App  {
    font-family: 'Inconsolata', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 6rem;
    margin-right: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
}

h1 {
    color: #3C1D09;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 3rem;
} 

h2 {
    text-align: center;
}

h6 {
    color: #3C1D09; 
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

