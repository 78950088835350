itle {
    font-size: 0rem;
   }
   
   .LandingPage {
       font-family: 'Inconsolata', sans-serif;
       color: #3C1D09; 
       display: flex;
       flex-direction: column;
       align-items: center;
       margin-bottom: 1rem;
       margin-left: 3rem;
       margin-right: 3rem;
       padding-left: 3rem;
       padding-right: 3rem;

   }

   .image {
    display: flex;
    flex-direction: column;
    background-position: center;
    background-image: url("https://user-images.githubusercontent.com/46304321/61234000-80500d00-a721-11e9-92e4-ab8a65bb9658.png");
    background-size: contain;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    height: 10rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}


h3 {
    text-align: center;
    margin-bottom: 3rem;
    color: #3C1D09;
}

button {
    text-align: center;
    font-family: 'Inconsolata', sans-serif;
    height: 2rem;
    width: 15rem;
    color: #3C1D09;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    margin-top: 3rem;
    background-color: Transparent;
    text-decoration: none;
    margin-bottom: 3rem;
    border: 1px solid #3C1D09;
    text-decoration: none;
}

button:hover {
    background-color: #3C1D09;
    opacity: 0.6;
    color: white;
  }
