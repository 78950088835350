@import url('https://fonts.googleapis.com/css?family=Inconsolata&display=swap');

html{
    background-image: url("https://user-images.githubusercontent.com/46304321/61320813-c5477280-a7f9-11e9-821f-efbdbf9a39eb.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover; 
}

.App  {
    font-family: 'Inconsolata', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 6rem;
    margin-right: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
}

h1 {
    color: #3C1D09;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 3rem;
} 

h2 {
    text-align: center;
}

h6 {
    color: #3C1D09; 
}
